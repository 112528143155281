import React, { useState } from 'react';
import BlindadosWrapper from 'Components/Blindados';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import NormativeTable from 'Images/normative-table.svg';
import Guns from 'Images/guns.svg';

const Blindados360Page = ({ location }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const header = (
    <StaticImage
      src="../../images/blindajes-360-cover.png"
      transformOptions={{ quality: 100 }}
      placeholder="blurred"
      aspectRatio={3 / 1}
    />
  );
  return (
    <BlindadosWrapper title="Blindajes 360" location={location} header={header}>
      <p className="_content-paragraph">
        Así llamamos al blindaje total del vehículo, lo que en el rubro se llama
        opaco (chapa) y transparente (vidrios). Se recomienda este tipo de
        blindaje para las personas que buscan más protección o están más
        expuestos al peligro diario.
        <br />
        Con la última tecnología, podemos desarrollar un blindado a medida de
        las necesidades y posibilidades de cada persona.
        <br />
        Un equipo de expertos te puede asesorar en el nivel de blindaje adecuado
        para tu vehículo. Nuestros clientes reciben junto a su vehículo
        blindado, un certificado garantía y profesionalidad.
      </p>
      <Link to="#contacto" className="_accent-text _link">
        Quiero más información
      </Link>
      <iframe
        classname="dangers--video"
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/E2g1BDhBzqg"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <Guns className="dangers--icons" />
      <h2 className="_sub-heading content--title">
        NORMA Y NIVELES DE RESISTENCIA BALÍSTICA
      </h2>
      <p className="_content-paragraph">
        La resistencia balística de los materiales utilizados en blindajes, se
        clasifica según esta norma en siete niveles, de acuerdo a su capacidad
        de protección:
      </p>
      <NormativeTable
        className="normative-table--thumb"
        style={{ width: '100%' }}
        onClick={() => setIsPopupOpen(true)}
      />
      {isPopupOpen && (
        <div
          className="normative-table--popup"
          onClick={() => setIsPopupOpen(false)}
        >
          <div className="normative-table--popup__content">
            <NormativeTable
              style={{ width: '100%' }}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      )}
    </BlindadosWrapper>
  );
};

export default Blindados360Page;
